<template>
  <div class="sticky box-content h-[4rem] safe-padding-top top-0 z-40 bg-white">
    <div class="py-2 w-full flex-shrink-0 flex justify-center">
      <MenuMobileWraper />
      <div
        class="flex items-center justify-between w-full px-3 sm:px-8 max-w-screen-2xl"
      >
        <div class="flex items-center">
          <MenuBack v-if="allowBack && isApp" />
          <MenuBurger v-else />

          <div class="flex items-center ml-3">
            <BaseLink theme="primary" :to="{ path: '/' }">
              <BaseIcon
                name="wn_logo"
                size="none"
                title="Communication Platform"
                class="h-12 w-14 mr-4"
              />
            </BaseLink>
            <div class="text-xl text-gray-900 mr-3 hidden md:block">
              {{ $t('header.title') }}
            </div>
          </div>
          <!-- <MenuDesktop class="hidden xl:block" /> -->
        </div>
        <div class="flex flex-shrink-0 items-center gap-2">
          <div class="flex items-center gap-1">
            <!-- <BaseLink to="/pro">
              <BaseButton
                size="xs"
                rounded="full"
                look="none"
                class="uppercase text-pro border border-pro hover:bg-pro hover:bg-opacity-10 active:border-pro focus:ring-pro focus:ring-2"
              >
                {{ $t('subscriptions.pro') }}
              </BaseButton>
            </BaseLink> -->

            <BaseButton
              v-if="!isApp && !authStore.auth"
              size="xs"
              rounded="full"
              look="none"
              class="text-gray-800 hover:text-gray-600 !px-1 mr-1 cky-banner-element"
            >
              <BaseIcon
                size="sm"
                name="outline_eye_off"
                class="w-[24px] h-[24px]"
              ></BaseIcon>
            </BaseButton>

            <template v-if="i18n.availableLocales.length > 1">
              <BaseMenu>
                <BaseButton
                  size="xs"
                  rounded="full"
                  look="none"
                  class="uppercase border-2 font-semibold border-gray-800 text-gray-800 hover:text-gray-600 hover:border-gray-600 mr-1.5"
                >
                  {{ $t(`languages.${i18n.locale.value}.abbr`) }}
                </BaseButton>

                <template #menu>
                  <div class="rounded-md w-16 bg-white shadow-md mt-2.5">
                    <template
                      v-for="(item, idx) in i18n.availableLocales"
                      :key="idx"
                    >
                      <button
                        v-if="item !== i18n.locale.value"
                        class="px-4 py-2 appearance-none block w-full text-left cursor-pointer text-gray-800 hover:text-gray-600"
                        @click="setLanguage(item)"
                      >
                        {{ $t(`languages.${item}.abbr`) }}
                      </button>
                    </template>
                  </div>
                </template>
              </BaseMenu>
            </template>

            <BaseLocaleLink
              v-if="authStore.auth"
              to="/users/me/tickets"
              class="flex"
            >
              <BaseButton
                size="xs"
                rounded="full"
                look="none"
                class="text-gray-800 hover:text-gray-600 !px-1 relative"
              >
                <BaseIcon
                  size="sm"
                  name="outline_ticket"
                  class="w-[24px] h-[24px]"
                />
                <div
                  v-if="ticketsStore.itemsToActivateCount?.order"
                  style="min-width: 1rem"
                  class="absolute top-0 -right-1 h-4 rounded-full flex justify-center items-center bg-pro-700 text-white text-xs"
                >
                  !
                </div>
              </BaseButton>
            </BaseLocaleLink>

            <BaseLocaleLink
              v-if="authStore.auth"
              to="/users/me/favorites"
              class="xs:flex hidden"
            >
              <BaseButton
                size="xs"
                rounded="full"
                look="none"
                class="text-gray-800 hover:text-gray-600 !px-1"
              >
                <BaseIcon
                  size="sm"
                  name="outline_bookmark"
                  class="w-[24px] h-[24px]"
                />
              </BaseButton>
            </BaseLocaleLink>

            <NotificationListMenu />
          </div>
          <div v-if="!authStore.auth" class="pl-5">
            <BaseButton
              theme="primary"
              look="border"
              @click="authStore.openLoginModal"
            >
              {{ $t('header.login') }}
            </BaseButton>
          </div>
          <template v-else>
            <HeaderProfileMenu />
          </template>
        </div>
      </div>
    </div>
  </div>
  <AuthLogin
    :is-open="authStore.isLoginModalOpen"
    @close="authStore.closeLoginModal"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuth } from '~/stores/auth'

const { isApp } = useDetect()
const authStore = useAuth()
const ticketsStore = useTickets()
const i18n = useI18n()

const allowBack = computed(() => {
  const history = useRouter().options.history.state
  const route = useRouter().currentRoute.value
  const isValidPrevRoute = history.back && !history?.replaced

  return isValidPrevRoute && route.meta.allowBack
})

const setLanguage = (langCode: string) => {
  useSetLanguage(langCode)
}

onServerPrefetch(async () => {
  authStore.auth && (await ticketsStore.fetchItemsToActivateCount())
})

onBeforeMount(async () => {
  authStore.auth && (await ticketsStore.fetchItemsToActivateCount())
})

watch(
  () => authStore.auth,
  async () => {
    authStore.auth && (await ticketsStore.fetchItemsToActivateCount())
  }
)
</script>
