<template>
  <transition
    enter-active-class="transition-opacity duration-300 ease-linear"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-300 ease-linear"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isMobileMenuVisible"
      class="flex flex-col bg-white shadow w-full h-full transition-all flex-shrink-0 select-none max-w-[18rem]"
    >
      <div class="sticky top-0 h-full xl:h-screen overflow-hidden">
        <div class="safe-padding-top flex flex-col h-full">
          <div
            class="w-full relative flex items-center flex-shrink-0 h-[4rem] px-4"
          >
            <MenuBurger icon="menu_close" />
            <BaseLink theme="primary" :to="{ path: '/' }">
              <BaseIcon
                name="logo"
                size="none"
                title="Games Industry Ecosystem"
                class="h-12 w-42"
              />
            </BaseLink>
          </div>
          <div
            class="h-auto overflow-y-auto overflow-x-hidden flex-1 flex flex-col scrollable-area"
          >
            <nav class="py-3">
              <div class="grid gap-1.5 px-4" @click="$emit('close')">
                <template v-for="item in menu" :key="item.id">
                  <MenuMobileButton v-if="!item.hidden" :link="item" />
                </template>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useMenu } from '~/stores/menu'

defineEmits(['close'])

const menuStore = useMenu()
const { menu, isMobileMenuVisible } = storeToRefs(menuStore)
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.25rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: rgba(107, 114, 128, 1);
}
/* Firefox fallback */
.scrollable-area {
  scrollbar-color: rgba(107, 114, 128, 1) transparent;
  scrollbar-width: thin;
}
</style>
