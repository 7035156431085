import type { MenuItem } from '~/models/menu'

interface MenuState {
  menu: MenuItem[]
  isMobileMenuVisible: boolean
}

export const useMenu = defineStore('menu', {
  state: (): MenuState => {
    return {
      menu: [
        {
          id: 'events',
          local: 'menu.events',
          route: '/event-info',
          icon: 'menu_events',
          type: 'page',
        },
        {
          id: 'academy',
          local: 'menu.academy',
          route: '/academy',
          icon: 'menu_academy',
          type: 'page',
        },
        {
          id: 'gamefinder',
          local: 'menu.gamefinder',
          route: {
            name: 'event-info-event',
            params: { event: 'gamefinder' },
          },
          icon: 'menu_gamefinder',
          type: 'page',
        },
        {
          id: 'talents',
          local: 'menu.talents',
          route: '/talents',
          icon: 'menu_jobs',
          type: 'page',
        },
        {
          id: 'news',
          local: 'menu.news',
          route: { name: 'news' },
          icon: 'menu_news',
          type: 'page',
        },
        {
          id: 'questions',
          local: 'menu.questions',
          route: { name: 'questions' },
          icon: 'menu_questions',
          type: 'page',
        },
        {
          id: 'video',
          local: 'menu.video',
          route: { name: 'video' },
          icon: 'menu_video',
          type: 'page',
        },
        // {
        //   id: 'marketplace',
        //   local: 'menu.marketplace',
        //   route: '/marketplace',
        //   icon: 'menu_marketplace',
        //   type: 'page',
        // },

        ...(useDetect().isApp
          ? [
              {
                id: 'contacts',
                local: 'menu.contacts',
                route: { name: 'contacts' },
                icon: 'menu_contacts',
                type: 'page',
              } as MenuItem,
            ]
          : []),
      ],
      isMobileMenuVisible: false,
    }
  },

  actions: {
    hideMenu() {
      this.isMobileMenuVisible = false
    },
    showMenu() {
      this.isMobileMenuVisible = true
    },
    toggleMenu() {
      if (this.isMobileMenuVisible) {
        this.hideMenu()
      } else {
        this.showMenu()
      }
    },
    // we want to make events menu item not active when selected event-info link from the menu
    isEventInfoRouteSelected(link: MenuItem) {
      const route = useRoute()
      return this.$state.menu.find(
        (item) =>
          typeof item.route !== 'string' &&
          link.id === 'events' &&
          item.route.name === 'event-info-event' &&
          ((route.name as string) || '').startsWith('event-info-event') &&
          route.params.event === item.route.params?.event
      )
    },
  },
})
