<template>
  <button
    class="flex items-center justify-center h-10 w-10 text-gray-500 rounded-xl hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
    @click="back"
  >
    <BaseIcon size="sm" name="outline_chevron_left" />
  </button>
</template>

<script setup lang="ts">
function back() {
  useRouter().go(-1)
}
</script>
