<template>
  <button
    class="flex items-center justify-center h-10 w-10 text-gray-500 rounded-xl hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
    @click="menu.toggleMenu"
  >
    <BaseIcon :name="icon" size="none" title="Open Menu" class="h-6 w-6" />
  </button>
</template>

<script setup lang="ts">
import { useMenu } from '~/stores/menu'

defineProps({
  icon: {
    type: String,
    default: 'menu_open',
  },
})

const menu = useMenu()
</script>
